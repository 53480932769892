import { Component, OnInit,  OnDestroy} from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { UtilStorageService } from '../admihair/service/util-storage.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Empresa } from '../admihair/api/dto/empresa';
import { Administradora } from '../admihair/api/dto/administradora';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent implements OnInit, OnDestroy{
    isLoggedIn = false;
    acesso?: any;
    eventBusSub?: Subscription;
    empresaSelecionada?: Empresa;
    isEmpresaSelecionada: boolean = false;
    administradoraSelecionada?: Administradora;
    isAdministradoraSelecionada: boolean = false;

    constructor(public layoutService: LayoutService,
        private utilStorageService: UtilStorageService,
        private _router: Router,
		    private _activatedRoute:ActivatedRoute) { }


    ngOnInit(): void {
        this.isLoggedIn = !!this.utilStorageService.getToken();
        if (this.isLoggedIn) {
          this.acesso = this.utilStorageService.getUser();
        }
        this.carregarEmpresaSessao();
        this.carregarAdministradoraSessao();

    }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }
    
  ngOnDestroy(): void {
    if (this.eventBusSub)
      this.eventBusSub.unsubscribe();
  }

  carregarEmpresaSessao(): void {
    this.empresaSelecionada = this.utilStorageService.getEmpresa();
    if(Object.keys(this.empresaSelecionada).length > 0){
      this.isEmpresaSelecionada = true;
    }
  }

  carregarAdministradoraSessao(): void {
    this.administradoraSelecionada = this.utilStorageService.getAdministradora();
    if(Object.keys(this.administradoraSelecionada).length > 0){
      this.isAdministradoraSelecionada = true;
    }
  }

  logout(): void {
    this.utilStorageService.signOut();
    this.isLoggedIn = false;
    this._router.navigate(["/auth/login"],{relativeTo:this._activatedRoute})
  }
}