import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { UtilStorageService } from '../service/util-storage.service';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private utilStorageService: UtilStorageService,
    private authService: AuthService,
    private _router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = this.utilStorageService.getToken();
    if (
      token != null &&
      req != null &&
      req.url != null &&
      req.url != '/auth/refreshtoken'
    ) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/login') && error.status === 401) {
        return this.handle401Error(authReq, next);
      }
      return throwError(error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const savedRefreshToken = this.utilStorageService.getRefreshToken();

      if (savedRefreshToken)
        return this.authService.refreshToken(savedRefreshToken).pipe(
          switchMap((refreshTokenResponse: any) => {
            this.isRefreshing = false;

            this.utilStorageService.saveToken(refreshTokenResponse.accessToken);
            this.refreshTokenSubject.next(refreshTokenResponse.accessToken);
            
            return next.handle(this.addTokenHeader(request, refreshTokenResponse.accessToken));
          }),
          catchError((err) => {
            if (err.status == HttpStatusCode.BadRequest) {
              this.redirecionarLogin();
            }

            this.isRefreshing = false;
            this.utilStorageService.signOut();
            return throwError(err);
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
     return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  }

  redirecionarLogin() {
    this._router.navigate(["auth/login"])
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];