import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cnpjFormat'
})
export class CnpjFormatPipe implements PipeTransform {

  transform(cnpj?: string): string {
    if (!cnpj) {
      return '';
    }

    // Remove caracteres não numéricos do valor
    const numericValue = cnpj.replace(/\D/g, '');

    // Aplica a máscara do CNPJ (99.999.999/9999-99)
    return numericValue.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
  }

}
