import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilStorageService } from '../../service/util-storage.service';
import { Empresa } from '../../api/dto/empresa';

@Injectable({
  providedIn: 'root'
})
export class SelecionarEmpresaGuard implements CanActivate {
  empresaSelecionada?: Empresa;
  isEmpresaSelecionada: boolean = false;

  constructor( private utilStorageService: UtilStorageService,
        private _router: Router,
		    private _activatedRoute:ActivatedRoute) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    this.carregarEmpresaSessao();
    if(!this.isEmpresaSelecionada){
      this._router.navigate(["/selecionar-empresa"],{relativeTo:this._activatedRoute})
    }
    return true;
  }
  

  carregarEmpresaSessao(): void {
    if(Object.keys(this.utilStorageService.getEmpresa()).length > 0){
      this.isEmpresaSelecionada = true;
    } else {
      this.isEmpresaSelecionada = false;
    }
  }
}
