export class PrimeNgTranslation {

    public static traducoes(): any {
        return {
            "startsWith": "Começa com",
            "contains": "Contém",
            "notContains": "Não contém",
            "endsWith": "Termina com",
            "equals": "Igual a",
            "notEquals": "Diferente de",
            "noFilter": "Sem filtro",
            "lt": "Menor que",
            "lte": "Menor ou igual a",
            "gt": "Maior que",
            "gte": "Maior ou igual a",
            "is": "É",
            "isNot": "Não é",
            "before": "Antes",
            "after": "Depois",
            "dateIs": "A data é",
            "dateIsNot": "A data não é",
            "dateBefore": "A data é antes de",
            "dateAfter": "A data é depois de",
            "clear": "Limpar",
            "apply": "Aplicar",
            "matchAll": "Corresponder a todos",
            "matchAny": "Corresponder a qualquer",
            "addRule": "Adicionar regra",
            "removeRule": "Remover regra",
            "accept": "Sim",
            "reject": "Não",
            "choose": "Escolher",
            "upload": "Carregar",
            "cancel": "Cancelar",
            "dayNames": ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
            "dayNamesShort": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            "dayNamesMin": ["D", "S", "T", "Q", "Q", "S", "S"],
            "monthNames": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            "monthNamesShort": ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            "dateFormat": "dd/mm/yy",
            "firstDayOfWeek": 0,
            "today": "Hoje",
            "weekHeader": "Sem",
            "weak": "Fraco",
            "medium": "Médio",
            "strong": "Forte",
            "passwordPrompt": "Digite uma senha",
            "emptyMessage": "Nenhum resultado encontrado",
            "emptyFilterMessage": "Nenhum resultado encontrado"
        };
    }


    /* traducoes disponiveis
    {
    "primeng": {
        "startsWith": "Starts with",
        "contains": "Contains",
        "notContains": "Not contains",
        "endsWith": "Ends with",
        "equals": "Equals",
        "notEquals": "Not equals",
        "noFilter": "No Filter",
        "lt": "Less than",
        "lte": "Less than or equal to",
        "gt": "Greater than",
        "gte": "Greater than or equal to",
        "is": "Is",
        "isNot": "Is not",
        "before": "Before",
        "after": "After",
        "dateIs": "Date is",
        "dateIsNot": "Date is not",
        "dateBefore": "Date is before",
        "dateAfter": "Date is after",
        "clear": "Clear",
        "apply": "Apply",
        "matchAll": "Match All",
        "matchAny": "Match Any",
        "addRule": "Add Rule",
        "removeRule": "Remove Rule",
        "accept": "Yes",
        "reject": "No",
        "choose": "Choose",
        "upload": "Upload",
        "cancel": "Cancel",
        "dayNames": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        "dayNamesShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        "dayNamesMin": ["Su","Mo","Tu","We","Th","Fr","Sa"],
        "monthNames": ["January","February","March","April","May","June","July","August","September","October","November","December"],
        "monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        "dateFormat": "mm/dd/yy",
        "firstDayOfWeek": 0,
        "today": "Today",
        "weekHeader": "Wk",
        "weak": 'Weak',
        "medium": 'Medium',
        "strong": 'Strong',
        "passwordPrompt": 'Enter a password',
        "emptyMessage": 'No results found',
        "emptyFilterMessage": 'No results found'
    }
    }
    */
} 