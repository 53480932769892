<ul class="layout-menu">
    <ng-container *ngIf="!loadingMenus">
        <ng-container *ngFor="let item of model; let i = index;">
            <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
            <li *ngIf="item.separator" class="menu-separator"></li>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="loadingMenus">
            <div class="skeleton-wrapper">
                <p-skeleton class="skeleton-item"></p-skeleton>
                <p-skeleton class="skeleton-item"></p-skeleton>
                <p-skeleton class="skeleton-item"></p-skeleton>
                <p-skeleton class="skeleton-item"></p-skeleton>
                <p-skeleton class="skeleton-item"></p-skeleton>
                <p-skeleton class="skeleton-item"></p-skeleton>
                <p-skeleton class="skeleton-item"></p-skeleton>
                <p-skeleton class="skeleton-item"></p-skeleton>
            </div>
    </ng-container>
</ul>