import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilStorageService } from '../../service/util-storage.service';
import { Usuario } from '../../api/dto/usuario';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private utilStorageService: UtilStorageService,
    private _router: Router,
    private _activatedRoute:ActivatedRoute) { }

    isLoggedIn = false;
    username?: string;
    isEmpresaSelecionada: boolean = false;
    usuario: Usuario =  this.utilStorageService.getUser();

  canActivate(
    
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.isLoggedIn = !!this.utilStorageService.getToken();
    if(!this.isLoggedIn){
      this._router.navigate(["/auth/login"],{relativeTo:this._activatedRoute});
    }
    if(this.usuario && this.usuario.primeiroAcesso){
      this._router.navigate(["/primeiro-acesso/" + this.usuario.id],{relativeTo:this._activatedRoute});
    }
    return true;
  }
}
