import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlServidor } from '../util/url-servidor';
import { Observable } from 'rxjs';
import { MenuDTO } from '../api/dto/menu-dto';

@Injectable({
    providedIn: 'root'
})
export class MenuServiceProjeto{

    constructor(private http: HttpClient) { }

    private url: string = UrlServidor.URL_BASE + 'menus';

    listarMenuUsuarioFranquia(administradoraId?: number, usuarioId?: number): Observable<MenuDTO[]>  {
        return this.http.get<MenuDTO[]>(this.url + "/administradora/" + administradoraId + "/usuario/"+ usuarioId);
    }

    listarMenuUsuarioEmpresa(empresaId?: number, usuarioId?: number): Observable<MenuDTO[]>  {
        return this.http.get<MenuDTO[]>(this.url + "/empresa/" + empresaId + "/usuario/"+ usuarioId);
    }

    listarTodos(): Observable<MenuDTO[]>{
        return this.http.get<MenuDTO[]>(this.url);
    }

    buscarPorId(id?: number): Observable<MenuDTO>{
        return this.http.get<MenuDTO>(this.url + '/' + id);
    }

    salvar(menu?: MenuDTO): Observable<MenuDTO>{
        return this.http.post<MenuDTO>(this.url, menu);
    }

    atualizar(menu?: MenuDTO): Observable<MenuDTO>{
        return this.http.put<MenuDTO>(this.url, menu);
    }
}