import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilStorageService } from '../../service/util-storage.service';
import { Administradora } from '../../api/dto/administradora';

@Injectable({
  providedIn: 'root'
})
export class SelecionarFranquiaGuard implements CanActivate {
  administradoraSelecionada?: Administradora;
  isAdministradoraSelecionada: boolean = false;

  constructor( private utilStorageService: UtilStorageService,
        private _router: Router,
		    private _activatedRoute:ActivatedRoute) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    this.carregarFranquiaSessao();
    if(!this.isAdministradoraSelecionada){
      this._router.navigate(["/selecionar-franquia"],{relativeTo:this._activatedRoute})
    }
    return true;
  }
  

  carregarFranquiaSessao(): void {
    if(Object.keys(this.utilStorageService.getAdministradora()).length > 0){
      this.isAdministradoraSelecionada = true;
    } else {
      this.isAdministradoraSelecionada = false;
    }
  }
}
