import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CnpjFormatPipe } from './cnpj/cnpj-format.pipe';
import { CpfFormatPipe } from './cpf/cpf-format.pipe';
import { PorcentagemFormatPipe } from './porcentagem/porcentagem-format.pipe';
import { CpfFormatPipe2 } from './cpf/cpf-format2.pipe';
import { TelefoneFormatPipe } from './telefone/telefone-format.pipe';



@NgModule({
  declarations: [
    CnpjFormatPipe,
    CpfFormatPipe,
    CpfFormatPipe2,
    PorcentagemFormatPipe,
    TelefoneFormatPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CnpjFormatPipe,
    PorcentagemFormatPipe,
    CpfFormatPipe,
    CpfFormatPipe2,
    TelefoneFormatPipe
  ]
})
export class FormatPipeModule { }
