<div class="layout-topbar">
    <div class="topbar-start">
        <button type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>
        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu">
            <li class="topbar-search">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText placeholder="Pesquisar" class="w-12rem sm:w-full">
                </span>
            </li>
            <li class="topbar-profile">
                <button type="button" class="p-link" (click)="onProfileButtonClick()">
                    <img src="assets/layout/images/avatar.png" alt="Profile" />
                </button>
            </li>
        </ul>
    </div>
</div>