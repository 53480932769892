import { Component, OnInit} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { PrimeNgTranslation } from './admihair/util/primeng-translation';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(private primengConfig: PrimeNGConfig) { }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        this.primengConfig.setTranslation(PrimeNgTranslation.traducoes());
    }
}
