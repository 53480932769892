import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlServidor } from '../util/url-servidor';

const AUTH_API = UrlServidor.URL_BASE;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    constructor(private http: HttpClient) { }


    login(login: string, password: string): Observable<any> {
        return this.http.post(AUTH_API + 'auth/login', {
          login,
          password
        }, httpOptions);
      }

      logout(): Observable<any> {
        return this.http.post(AUTH_API + 'auth/signout', {});
      }
    
      register(nome: string , apelido: string, whatsapp: string, email: string, senha: string, tipo: string): Observable<any> {
        return this.http.post(AUTH_API + 'usuarios/criar-conta', {
          nome,
          apelido,
          whatsapp,
          email, 
          senha,
          tipo
        }, httpOptions);
      }

     
    
      refreshToken(token: string) {
        return this.http.post(AUTH_API + 'auth/refreshtoken', {
          refreshToken: token
        }, httpOptions);
      }

      
}